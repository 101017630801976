<template>
  <div v-if="checkPermission('list', 'action', 'message-reports')">
    <topic-component :topic="$t('SideBarItems.reports.list.messageReports')"/>
    <b-card>
      <b-row>
        <b-col lg="4" md="6" sm="12">
          <b-form-group label-cols="12"
                        :label="$t('common.dateTime')" label-for="input-sm">
            <date-timerange-compponent v-model="time"/>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="3" sm="4">
          <select-filter-component :label-select="$t('report.otpList.senderName')"
                                   v-model="form.sender_name"
                                   :options="senderNameList"
                                   field-text="text"
          />
        </b-col>
        <b-col lg="4" md="3" sm="4">
          <b-form-group label-cols="12"
                        :label="$t('report.messageList.to')" label-for="input-sm">
            <b-form-input v-model="form.to"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="3" sm="4">
          <select-filter-component :label-select="$t('report.messageList.status')"
                                   v-model="form.send_status"
                                   :options="statusList"
          />
        </b-col>
        <b-col style="margin-top: 35px">
          <button type="button" class="btn waves-effect waves-light btn-gradient " @click="findMessage">
            {{ $t('common.Search') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
            {{ $t('common.resetSearch') }}
          </button>
        </b-col>
      </b-row>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{ $t('common.perPage') }}</span>
            <div>
              <b-form-select
                  v-model="form.$limit"
                  :options="pageOptions"
                  size="sm"
                  @change="findMessage"
              />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll"/>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-table
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
          :busy="isBusy"
          :empty-text="$t('empty')"
          show-empty
      >
        <template #head()="{ label, field: { key, sortable }}">
          {{ $t(label) }}
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong> {{ $t('loading') }}</strong>
          </div>
        </template>
        <template #empty="scope">
          <empty-table/>
        </template>
        <template #cell(createdAt)="data">
          {{ changeDateFormat(data.item.createdAt) }}
        </template>
        <template #cell(sms_count)="data">
          <span class="text-bold text-primary">{{ changeNumberFormat(data.item.sms_amount) }}</span>
        </template>
        <template #cell(gateway_status)="data">
          <span class="text-bold">  {{ filterValue(smsGWStatusList, data.item.status_gateway_desc) }}</span>
        </template>
        <template #cell(send_status)="data">
          <b-badge pill :class="colorButton(data.item.status)" class="px-2 pt-1 text-white badge tooltip-custom" v-b-tooltip.hover="filterStatusTooltip(data.item.status)">
            {{ filterValue(statusList, data.item.status) }}
          </b-badge>
        </template>
        <template #cell(send_type)="data">
          <span>  {{ filterValue(sendType, data.item.send_type) }}</span>
        </template>
        <template #cell(action)="data">
          <i style="color: #0d73d9" class="action-button icon-eye" @click="viewDetail(data.item)"/>
        </template>
      </b-table>
      <b-row>
        <div class="ml-auto">
          <b-pagination
              v-model="form.$skip"
              :total-rows="totalRows"
              :per-page="form.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="findMessage"
          ></b-pagination>
        </div>
      </b-row>
      <message-detail :sms-detail="smsDetail"/>
    </b-card>
  </div>
</template>

<script>

import DateTimerangeCompponent from "@/components/common/dateTimeRangeComponent";
import TopicComponent from "@/components/common/topicComponent";
import MessageDetail from "@/views/reports/message/detail";
import reportApi from "@/repository/reportApi";
import moment from "moment";
import senderIdApi from "@/repository/senderIdApi";
import alert from "@/common/alert";
import functionsCommon from "@/common/functions";
import masterData from "@/common/masterData.json";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import functions from "@/common/functions";
import {validatePhoneNumber} from "@sms2pro/sms2pro"
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "message",
  components: {
    TotalListComponent,
    EmptyTable, SelectFilterComponent, MessageDetail, TopicComponent, DateTimerangeCompponent
  },
  data: () => ({
    totalAll: 0,
    form: {
      date_from: null,
      date_to: null,
      sender_name: null,
      sms_type: null,
      send_status: null,
      to: '',
      $skip: 1,
      $limit: 10,
    },
    isBusy: false,
    smsDetail: {},
    time: [
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
    ],
    fields: [
      {
        key: "createdAt",
        label: "report.messageList.date",
      },
      {
        key: "sender_name",
        label: "report.otpList.senderName",
      },
      {
        key: "to",
        label: "report.messageList.to",
      },
      {
        key: "sms_count",
        label: "report.messageList.countSMS",
        class: "text-center"
      },
      {
        key: "send_type",
        label: "report.messageList.typeSend",
        class: "text-center"
      },
      {
        key: "gateway_status",
        label: "report.messageList.statusGateway",
        class: "text-center"
      },
      {
        key: "send_status",
        label: "report.messageList.status",
      },
      {
        key: "action",
        label: "report.messageList.action",
      },
    ],
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
    senderNameList: [],
    statusList: masterData.smsStatusList,
    newStatusList: [],
    smsGWStatusList: masterData.smsGWStatusList,
    sendType: masterData.sendType
  }),
  mounted() {
    this.findMessage()
    this.findSenderId()
  },
  methods: {
    colorButton(data) {
      return functionsCommon.colorButtonStatus(data)
    },
    viewDetail(data) {
      this.smsDetail = data
      this.$bvModal.show('modal-message-detail')
    },
    validatePhoneNumber(data) {
      const phone = data ? validatePhoneNumber(data) : ''
      return phone ? phone : data
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    changeNumberFormat(date) {
      return date ? functionsCommon.changeNumberFormat(date) : '0'
    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    findMessage() {
      this.isBusy = !this.isBusy
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z'
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      this.form.to = this.validatePhoneNumber(this.form.to)
      reportApi.getMessageAll(_.cloneDeep(this.form)).then(response => {
        if (response?.codeSYS === '001') {
          this.totalAll = response.total
          this.totalRows = response.total
          this.items = response.data
        }
      }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    resetSearch() {
      this.form = {
        date_from: null,
        date_to: null,
        sender_name: null,
        sms_type: null,
        send_status: null,
        to: '',
        $skip: 1,
        $limit: 10,
      }
      this.time = [
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
      ]
      this.findMessage()
    },
    findSenderId() {
      const data = {
        type: 'quick-send',
      }
      senderIdApi.getTempAll(data).then(resp => {
        this.senderNameList = resp.data.map(item => {
          return {
            value: item?.sender_id,
            text: item?.sender_id
          }
        })
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    filterStatusTooltip(status) {
      switch (status) {
        case 0:
          return this.$t('report.messageList.statusInformation.success');
        case 1:
          return this.$t('report.messageList.statusInformation.processing');
        case 2:
          return this.$t('report.messageList.statusInformation.failed');
        case 3:
          return this.$t('report.messageList.statusInformation.expired');
        case 4:
          return this.$t('report.messageList.statusInformation.blocked');
        case 7:
          return this.$t('report.messageList.statusInformation.waiting_dr');
        case 8:
          return this.$t('report.messageList.statusInformation.not_process');
        case 10:
          return this.$t('report.messageList.statusInformation.blacklist');
        case 11:
          return this.$t('report.messageList.statusInformation.spam');

      }
    }
  }
};
</script>
<style lang="scss">
.action-button {
  cursor: pointer;
}
.tooltip-custom {
  cursor: pointer;
}
.tooltip-inner {
  max-width: 100% !important;
}
</style>
