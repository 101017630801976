<template>
  <b-modal
      id="modal-message-detail"
      ok-only
      ok-title="OK"
      modal-class="modal-primary"
      no-close-on-backdrop
      centered
      title=""
  >
    <template #modal-header="{ close }">
      <div></div>
      <i class="fas fa-times hover-icon action-button" @click="close"/>
    </template>
    <b-card-text>
      <b-row class="py-3  align-items-center bg-light">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{$t('report.messageList.senderId')}} :</label>
        </b-col>
        <b-col sm="8">
          <label class="fw-medium mb-0"> {{smsDetail.sender_name}}</label>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{$t('report.messageList.to')}} : </label>
        </b-col>
        <b-col sm="8">
          <label class="fw-medium mb-0 text-primary"> {{smsDetail.to}}</label>
        </b-col>
      </b-row>
      <b-row class="py-3  align-items-center bg-light">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{$t('report.messageList.message')}} :</label>
        </b-col>
        <b-col sm="8">
          <div style="width:270px!important; overflow: auto ">
            <label class="fw-medium mb-0"> {{smsDetail.message}}</label>
          </div>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{$t('report.messageList.status_dr')}} : </label>
        </b-col>
        <b-col sm="8">
          <div class="fw-medium mb-0 text-primary">
            <div v-if="smsDetail?.res_dr?.code">
              {{ smsDetail?.res_dr?.code }}
              {{ smsDetail?.res_dr?.detail }}
            </div>
            <div v-else-if="!smsDetail?.res_dr?.code && smsDetail?.status === 7" class="text-warning">
              {{ 'Waiting DR' }}
            </div>
            <div v-else>
              -
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center bg-light">
        <b-col sm="4" class="text-left text-md-right ">
          <label class="fw-medium mb-0"> {{$t('report.messageList.statusGateway')}} : </label>
        </b-col>
        <b-col sm="8">
          <div style="width:270px!important; overflow: auto ">
            <label class="fw-medium mb-0 ">{{smsDetail?.res_gateway?.code}} {{smsDetail?.res_gateway?.detail}}</label>
          </div>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{$t('report.messageList.cost')}} : </label>
        </b-col>
        <b-col sm="8">
          <label class="fw-medium mb-0 text-primary"> {{changeNumberFormat(smsDetail.cost)}}</label>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center bg-light">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{$t('report.messageList.countSMS')}} : </label>
        </b-col>
        <b-col sm="8">
          <label class="fw-medium mb-0 "> {{changeNumberFormat(smsDetail.sms_amount)}}</label>
        </b-col>
      </b-row>
    </b-card-text>
  </b-modal>
</template>
<script>


import masterData from "@/common/masterData.json";
import functionsCommon from "@/common/functions";

export default {
  name: "messageDetail",
  data() {
    return {
      statusList: masterData.smsStatusList,
    }
  },
  props: {
    smsDetail: {
      type: Object,
      required: false
    }
  },
  methods: {
    changeNumberFormat(date) {
      return date ? functionsCommon.changeNumberFormat(date) : '0'
    },
    colorButton(data) {
      return functionsCommon.colorButtonStatus(data)
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
  }
}
</script>

<style scoped>

</style>